code[class*="language-"], pre[class*="language-"] {
  font-size: 14px;
  line-height: 1.375;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  -moz-tab-size: 2;
  -o-tab-size: 2;
  tab-size: 2;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  background: #272822;
  color: #f8f8f2;
}
pre[class*="language-"] {
  padding: 1.5em 0;
  margin: .5em 0;
  overflow: auto;
}
:not(pre) > code[class*="language-"] {
  padding: .1em;
  border-radius: .3em;
}
.token.comment, .token.prolog, .token.doctype, .token.cdata {
  color: #75715e;
}
.token.punctuation {
  color: #f8f8f2;
}
.token.namespace {
  opacity: .7;
}
.token.operator, .token.boolean, .token.number {
  color: #fd971f;
}
.token.property {
  color: #f4bf75;
}
.token.tag {
  color: #66d9ef;
}
.token.string {
  color: #a1efe4;
}
.token.selector {
  color: #ae81ff;
}
.token.attr-name {
  color: #fd971f;
}
.token.entity, .token.url, .language-css .token.string, .style .token.string {
  color: #a1efe4;
}
.token.attr-value, .token.keyword, .token.control, .token.directive, .token.unit {
  color: #a6e22e;
}
.token.statement, .token.regex, .token.atrule {
  color: #a1efe4;
}
.token.placeholder, .token.variable {
  color: #66d9ef;
}
.token.deleted {
  text-decoration: line-through;
}
.token.inserted {
  border-bottom: 1px dotted #f9f8f5;
  text-decoration: none;
}
.token.italic {
  font-style: italic;
}
.token.important, .token.bold {
  font-weight: bold;
}
.token.important {
  color: #f92672;
}
.token.entity {
  cursor: help;
}
pre > code.highlight {
  outline: 0.4em solid #f92672;
  outline-offset: .4em;
}
