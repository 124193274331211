@import './vendor/bootstrap';
@import './vendor/prism';

/* Colors */
:root {
  --lightgray: #e0e0e0;
  --gray: #C0C0C0;
  --darkgray: #333;
  --navy: #17050F;
  --blue: #082840;
  --white: #fff;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, system-ui, sans-serif;
  color: var(--darkgray);
  background-color: var(--white);
}

article h2, h3, h4, h5, h6 {
  clear: both;
  line-height: 1.4;
  margin: 0 0 0.75em;
  padding: 1.5em 0 0;
  overflow-wrap: break-word;
}

#hero-image-section {
  min-height: 80vh;
  position: relative;
  display: flex;
  align-items: center;

  font-size: calc((1.2 - 1) * 1.2vh + 1rem);

  .hero-image-cover {
    object-position: 50% 50%; width: 100%; height: 100%; object-fit: cover;
  }

  .section-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .section-background-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.27;
    background-color: #625448;
  }

  .content-wrapper {
    width: 100%;
    box-sizing: content-box;
    display: flex;
    position: relative;
    padding-top: 10vmax;
    padding-bottom: 10vmax;
    justify-content: center;
    margin: 0 auto;
  }

  .site-title {
    color: white;
    clear: none;
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 1.25;
    letter-spacing: 0.08em;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }

  .site-description {
    color: white;
    font-size: 0.8125rem;
    margin-bottom: 0;
    overflow-wrap: break-word;
  }

  @media screen and (min-width: 48em) {
    .site-title {
      font-size: 2.25rem
    }

    .site-description {
      font-size: 1rem;
    }
  }
}

blockquote {
  margin: 0 0 1rem;
  background-color: #e5ecf4;
  margin-top: 2em;
  position: relative;
  padding: 1rem;
}
@media only screen and (min-width: 992px) {
  body.single blockquote {
    padding: 1.5em;
  }
}

// IMAGES
.small-image {
  width: 50%;
  max-width: 500px;
  height: auto;
}

.fit-content-image {
  width: 100%;
  max-width: 700px;
  height: auto;
}

.lm-card-cover-image {
  width: 100%;
  max-height: 350px;
  object-fit: cover;
}

.lm-author-thumb img {
  border-radius: 63% 37% 30% 70% / 50% 45% 55% 50%;
}

/* Global stylesheet */
* {
  box-sizing: border-box;
}

p:last-child {
  margin-bottom: 0;
}
p,
.tmpl-post li {
  line-height: 1.5;
}
main :first-child {
  margin-top: 0;
}

header:after {
  content: "";
  display: table;
  clear: both;
}
table {
  margin: 1em 0;
}
table td,
table th {
  padding-right: 1em;
}

pre,
code {
  font-family: Consolas, Menlo, Monaco, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", "Courier New", Courier, monospace;
  line-height: 1.5;
}
pre {
  font-size: 14px;
  line-height: 1.375;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  -moz-tab-size: 2;
  -o-tab-size: 2;
  tab-size: 2;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  padding: 1em;
  margin: .5em 0;
  background-color: #f6f6f6;
}
.highlight-line {
  display: block;
  padding: 0.125em 1em;
  text-decoration: none; /* override del, ins, mark defaults */
  color: inherit; /* override del, ins, mark defaults */
}

/* allow highlighting empty lines */
.highlight-line:empty:before {
  content: " ";
}
/* avoid double line breaks when using display: block; */
.highlight-line + br {
  display: none;
}

.highlight-line-isdir {
  color: #b0b0b0;
  background-color: #222;
}
.highlight-line-active {
  background-color: #444;
  background-color: hsla(0, 0%, 27%, .8);
}
.highlight-line-add {
  background-color: #45844b;
}
.highlight-line-remove {
  background-color: #902f2f;
}

/* Header */
.home {
  padding: 0 1rem;
  float: left;
  margin: 1rem 0; /* 16px /16 */
  font-size: 1em; /* 16px /16 */
}
.home :link:not(:hover) {
  text-decoration: none;
}

/* Nav */
.nav {
  padding: 0;
  list-style: none;
  float: left;
}
.nav-item {
  display: inline-block;
  margin-right: 1em;
}
.nav-item a[href]:not(:hover) {
  text-decoration: none;
}
.nav-item-active {
  font-weight: 700;
  text-decoration: underline;
}

/* Warning */
.warning {
  background-color: #ffc;
  padding: 1em 0.625em; /* 16px 10px /16 */
}
.warning ol:only-child {
  margin: 0;
}

/* Direct Links / Markdown Headers */
.direct-link {
  font-family: sans-serif;
  text-decoration: none;
  font-style: normal;
  margin-left: .1em;
}
a[href].direct-link,
a[href].direct-link:visited {
  color: transparent;
}
a[href].direct-link:focus,
a[href].direct-link:focus:visited,
:hover > a[href].direct-link,
:hover > a[href].direct-link:visited {
  color: #aaa;
}

// Center instagram content
.instagram-media {
  margin: 0 auto !important;
  display: block !important;
}
